<template>
  <div class="data-loader d-flex justify-content-center align-items-center items-center">
      <div id="">
          <div class="spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Loading...</span>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'data-loader',
}
</script>
<style lang="css">
  .data-loader{
    height: 55vh;
  }
</style>