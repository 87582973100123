<script>
import Modal from "@/components/modal.vue";

export default {
  name: "loading-modal",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {Modal},
  watch: {
    value(newValue) {
      this.visible = newValue
    }
  },
  data() {
    return {
      visible: false
    }
  },
}
</script>

<template>
  <Modal v-model="visible">
    <div class="text-center">
      <b-spinner variant="success" :style="{width: '150px', height: '150px'}" />
    </div>
  </Modal>
</template>

<style scoped>
  button {
    display: none;
  }
</style>