<script>
  import API from "@/plugins/axios-method";
  import LoadingModal from "@/components/loading-modal.vue";
  import {getUrlPrefixToShow} from "@/helpers/functions";
  import {SUPERVISOR_} from "@/helpers/constant/roles";
  // import Pagination from "@/components/dashboard/Pagination.vue";

  export default {
    name: 'user-table-card',
    components: {LoadingModal},
    props: {
      users: {
        type: Array,
        default(){
         return []
        }
      },
      role: {
        type: String,
        default: SUPERVISOR_
      },
      pageName: {
        type: String,
        default: null
      }
    },
    data(){
      return {
        loadingModal: false,
      }
    },
    methods: {
      getUrlPrefixToShow,
      resetPassword(user){
        const confirmation = confirm("Voulez-vous vraiment réinitialiser le mot de passe de cet utilisateur ?")
        if (!confirmation) return
        this.loadingModal = true

        API.patch(`/admin/users/${user.uniqId}/reset-password`, {})
            .then(() => {})
            .catch(() => {})
            .finally(() => {
              this.loadingModal = false
            })
      },
      getAge(birthDate){
        const currentYear = new Date().getFullYear()
        const birthYear =  Number(birthDate.split('-')[0])

        return  currentYear - birthYear
      },
      getCreatedAndUpdatedDates(date){
        return date.split('T')[0]
      },
      updateStatus(id,status){

        const confirmation = confirm("Voulez-vous vraiment changer le statut de cet utilisateur ?")
        if (!confirmation) return window.location.reload()

        API.patch(`/admin/users/${id}/status`, {
          status: Number(!status)
        })
            .then(() => {

            })
            .catch(() => {
              window.location.reload()
            })
      },
      openUpdateUserModal(user){
        this.$emit('openUpdateUserModal', user)
      },
      formatNumber(num) {
        if (num >= 1000000) {
          this.unit = 'M'
          return Math.ceil(num / 1000000);
        } else if (num >= 100000) {
          this.unit = 'K'
          return Math.ceil(num / 1000);
        } else {
          return num;
        }
      },
      formatPhoneNumber(phoneNumber){
        return phoneNumber.split('237')[1]
      }
    },
    computed: {

    }
  }
</script>

<template>
    <div class="table-responsive">
      <loading-modal v-model="loadingModal"/>
      <table class="table table-hover table-striped align-middle table-nowrap mb-0">
        <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col" v-if="pageName === 'commercials'" >Code</th>
          <th scope="col">Nom</th>
          <!-- <th scope="col" >Inscription</th> -->
          <th scope="col" >Epargne totale</th>
          <th scope="col">Téléphone</th>
          <th scope="col">Email</th>
          <th scope="col" class="text-center">Limite de compte</th>
          <th scope="col" class="text-center">Comptes créés</th>
          <th scope="col">Ville</th>
          <th scope="col">Statut</th>
          <th scope="col">Créé le</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <th scope="row">{{index + 1}}</th>
          <th v-if="pageName === 'commercials'">{{user.codes[0]?.name}}</th>
          <td>{{ user.lastname || '' }} {{ user.firstname || '' }} </td>
          <!-- <td class="text-center">
            <i v-if="user.registered" class="ri-checkbox-circle-line align-middle text-success"></i>
            <i v-else class="ri-close-circle-line align-middle text-danger"></i>
          </td> -->
          <td>{{ formatNumber(user.totalDeposit) }} XAF</td>
          <td>{{ formatPhoneNumber(user.phoneNumber) }}</td>
          <td>{{ user.email }}</td>
          <td class="text-center">{{ user.accountLimit }}</td>
          <td class="text-center">{{ user.registeredAccounts }}</td>
          <td>{{ user.city?.name }}, {{user.city?.region?.name}}</td>
          <td>
            <div class="form-check form-switch">
              <input class="form-check-input" @change="updateStatus(user.uniqId, user.status)" type="checkbox" role="switch" :id="'SwitchCheck'+ index" :checked="user.status">
            </div>
          </td>
          <td>{{ getCreatedAndUpdatedDates(user.createdAt) }}</td>
          <td>
            <div class="hstack gap-3 fs-15">
              <router-link :to="getUrlPrefixToShow(this.role, this.pageName) + user.uniqId" class="scale-on-hover link-primary">
                <i class="ri-eye-fill"></i>
              </router-link>
              <a href="javascript:void(0);" @click="openUpdateUserModal(user)" class="scale-on-hover link-primary"><i class="ri-pencil-fill"></i></a>
              <a href="javascript:void(0);" @click="resetPassword(user)" title="Réinitialiser le mot de passe" class="scale-on-hover" ><i class="las la-redo-alt"></i></a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
</template>

<style scoped>
  .scale-on-hover:hover {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
  }
</style>