<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import UserTableCard from "@/components/dashboard/user/user-table-card.vue";
import DataLoader from "@/components/data-loader.vue";
import "@vueform/multiselect/themes/default.css";
import FilterForm from "@/components/dashboard/filter-form.vue";
import API from "@/plugins/axios-method";
import LoadingModal from "@/components/loading-modal.vue";
export default {
  page: {
    title: "Adhérents",
    meta: [{ name: "description", content: 'User Management' }],
  },
  components: {
    LoadingModal,
    FilterForm,
    DataLoader,
    UserTableCard,
    Layout,
    PageHeader
  },
  data() {
    return {
      users: [],
      showDataLoader: true,
      page: 1,
      totalItems: 0,
      isSubmitted: false
    }
  },
  mounted() {
  },
  methods: {
    submitFilter(payload){
      this.users = []
      this.isSubmitted = true
      API.post('/admin/search', payload)
          .then(response => {
            response.data.searchResults[0].users.map(u => (
              this.users.push(u)
            ))
          })
          .finally(() => {
            this.isSubmitted = false
          })
    },
    updateLoader(){
      this.showDataLoader = false
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Recherche des adhérents'"
        :show-add-button="false"
        :show-search="false"
    />
    <filter-form @filterSubmitted="submitFilter" @cities-loaded="updateLoader"/>
    <loading-modal v-model="isSubmitted"/>
    <DataLoader v-if="showDataLoader"/>
    <section v-if="users.length > 0 && !showDataLoader">
      <div class="">
        <div class="card">
          <div class="card-body">
            <UserTableCard
                :users="users"
                :page-name="'members'"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>